<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="hasError"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_data') }}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-card>
        <b-card-header>
          <div class="text-primary h2 col-12">
            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="9"
                xl="9"
              >
                <span class="font-weight-bold">
                  {{ $t('fullInvoice.section.edit') }}
                </span>
              </b-col>
            </b-row>
          </div>
        </b-card-header>
        <b-card-body>
          <validation-observer
            ref="elementForm"
            #default="{ invalid }"
          >
            <b-form
              @submit.prevent="save"
            >
              <b-row>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="number"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.number') }}
                    </template>
                    <b-form-input
                      v-model="elementData.number"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="arl"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.client') }}
                    </template>
                    <b-form-input
                      v-model="elementData.arl.name"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="nit"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.nit') }}
                    </template>
                    <b-form-input
                      v-model="elementData.arl.nit"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="date"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.date') }}
                    </template>
                    <b-form-input
                      v-model="invoiceDate"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <date-picker
                    :element-required="false"
                    element-field="arl_filed_date"
                    :root-element-data="elementData"
                    :element-label="$t('fullInvoice.object.arl_filed_date')"
                  />
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="arl_filed_number"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.arl_filed_number') }}
                    </template>
                    <b-form-input
                      v-model="elementData.arl_filed_number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="value"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.value') }}
                    </template>
                    <b-form-input
                      v-model="elementData.value"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="iva"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.iva') }}
                    </template>
                    <b-form-input
                      v-model="elementData.iva"
                      type="number"
                      min="0"
                      step="1"
                      @input="val => elementData.iva = Math.round(parseFloat(val) || 0)"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="charged_value"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.charged_value') }}
                    </template>
                    <b-form-input
                      v-model="elementData.charged_value"
                      type="number"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="fuente"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.fuente_edit') }}
                    </template>
                    <b-form-input
                      v-model="elementData.fuente"
                      type="number"
                      min="0"
                      step="1"
                      @input="val => elementData.fuente = Math.round(parseFloat(val) || 0)"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="ica"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.ica_edit') }}
                    </template>
                    <b-form-input
                      v-model="elementData.ica"
                      type="number"
                      min="0"
                      step="1"
                      @input="val => elementData.ica = Math.round(parseFloat(val) || 0)"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="other_taxes"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.other_taxes') }}
                    </template>
                    <b-form-input
                      v-model="elementData.other_taxes"
                      type="number"
                      min="0"
                      step="1"
                      @input="val => elementData.other_taxes = Math.round(parseFloat(val) || 0)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="net_value"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.neto') }}
                    </template>
                    <b-form-input
                      v-model="elementData.net_value"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="received_value"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.received_value') }}
                    </template>
                    <b-form-input
                      v-model="elementData.received_value"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="balance"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.balance') }}
                    </template>
                    <b-form-input
                      v-model="elementData.balance"
                      type="number"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <date-picker
                    :element-required="false"
                    element-field="pay_date"
                    :root-element-data="elementData"
                    :element-label="$t('fullInvoice.object.pay_date')"
                  />
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="credit"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.credit') }}
                    </template>
                    <b-form-input
                      v-model="elementData.credit"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="arl"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.status') }}
                    </template>
                    <v-select
                      v-model="elementData.billing_status_id"
                      :options="invoiceStatusList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="$t('list.defaultOption')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-for="observations"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.observations') }}
                      <span v-if="requiresObservations" class="text-danger">*</span>
                    </template>
                    <b-form-textarea
                      id="observations"
                      v-model="elementData.observations"
                      maxlength="500"
                      max="500"
                      :state="observationsValidState"
                    />
                    <b-form-invalid-feedback v-if="requiresObservations && (!elementData.observations || elementData.observations.trim() === '')">
                      Las observaciones son obligatorias para este estado
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <br>
              <div class="text-primary h2 col-12">
                <b-row>
                  <!-- Right Col: Table -->
                  <b-col
                    cols="9"
                    xl="9"
                  >
                    <span class="font-weight-bold">
                      {{ $t('fullInvoice.section.patient_info') }}
                    </span>
                  </b-col>
                </b-row>
              </div>

              <b-row>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="document_type"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.document_type') }}
                    </template>
                    <b-form-input
                      v-model="patient.document_type.value"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="identity"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.identity') }}
                    </template>
                    <b-form-input
                      v-model="patient.identity"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="file_number"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.file_number') }}
                    </template>
                    <b-form-input
                      v-model="patient.identity"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  lg="3"
                  md="3"
                >
                  <b-form-group
                    label-for="first_name"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.first_name') }}
                    </template>
                    <b-form-input
                      v-model="patient.first_name"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="3"
                  md="3"
                >
                  <b-form-group
                    label-for="second_name"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.second_name') }}
                    </template>
                    <b-form-input
                      v-model="patient.second_name"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="3"
                  md="3"
                >
                  <b-form-group
                    label-for="first_surname"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.first_surname') }}
                    </template>
                    <b-form-input
                      v-model="patient.first_surname"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="3"
                  md="3"
                >
                  <b-form-group
                    label-for="second_surname"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.second_surname') }}
                    </template>
                    <b-form-input
                      v-model="patient.second_surname"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group label-for="services">
                    <template v-slot:label>
                      {{ $t(isPartiallyVoided ? 
                        'fullInvoice.object.services_partial_void' : 
                        'fullInvoice.object.services') 
                      }}
                    </template>
                    
                    <div v-if="isPartiallyVoided">
                      <div
                        v-for="invoice in invoiceItems"
                        :key="invoice.id"
                        :class="['service-item mb-2', {
                          'service-inactive': !selectedServices.includes(invoice.id),
                          'service-cancelled': invoice.cancelled
                        }]"
                      >
                        <b-row align-v="center">
                          <b-col cols="12" lg="6">
                            <b-form-checkbox
                              v-model="selectedServices"
                              :value="invoice.id"
                              @change="handleServiceSelection"
                            >
                              {{ invoice.document }}
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-group>
                              <template v-slot:label>
                                {{ $t('fullInvoice.object.value') }}
                              </template>
                              <b-form-input
                                :value="invoice.value"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-group>
                              <template v-slot:label>
                                {{ $t('fullInvoice.object.iva') }}
                              </template>
                              <b-form-input
                                :value="invoice.iva"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-group>
                              <template v-slot:label>
                                {{ $t('fullInvoice.object.charged_value') }}
                              </template>
                              <b-form-input
                                :value="invoice.value_iva"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        v-for="invoice in invoiceItems"
                        :key="invoice.id"
                        :class="['service-item mb-2', {
                          'service-cancelled': invoice.cancelled
                        }]"
                      >
                        <b-row align-v="center">
                          <b-col cols="12" lg="6">
                            <span>{{ invoice.document }}</span>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-group>
                              <template v-slot:label>
                                {{ $t('fullInvoice.object.value') }}
                              </template>
                              <b-form-input
                                :value="invoice.value"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-group>
                              <template v-slot:label>
                                {{ $t('fullInvoice.object.iva') }}
                              </template>
                              <b-form-input
                                :value="invoice.iva"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-group>
                              <template v-slot:label>
                                {{ $t('fullInvoice.object.charged_value') }}
                              </template>
                              <b-form-input
                                :value="invoice.value_iva"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="d-flex justify-content-end">
                <b-button
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  @click="router().go(-1)"
                >
                  {{ $t('actions.back') }}
                </b-button>

                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="!canSubmitForm"
                >
                  {{ $t('actions.save') }}
                </b-button>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>

    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BCard, BCardHeader, BCardBody, BFormInput, BFormGroup, BForm, BFormTextarea, BAlert, BFormCheckbox, BFormCheckboxGroup, BFormInvalidFeedback  
} from 'bootstrap-vue'

import { ValidationObserver } from 'vee-validate'

import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatePicker from '@/views/core/DatePicker.vue'
import moment from 'moment'
import vSelect from 'vue-select'
import ElementStoreModule from '../storeModule'
import '@core/scss/vue/libs/vue-select.scss'

export default {

  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BFormGroup,
    BForm,
    ValidationObserver,
    DatePicker,
    BFormTextarea,
    vSelect,
    BAlert,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInvalidFeedback
  },
  data() {
    return {
      patient: {
        document_type: {
          value: undefined,
        },
      },
      elementData: {
        arl_filed_date: undefined,
        pay_date: undefined,
        arl: {
          value: undefined,
          nit: undefined,
        },
      },
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      type: undefined,
      id: undefined,
      invoiceStatusList: [],
      invoiceItems: [],
      invoiceDate: undefined,
      hasError: false,
      selectedServices: [],
      INVOICE_STATES: {
        FULLY_VOIDED: 1969,
        PARTIALLY_VOIDED: 1970,
        FULLY_PUNISHED: 2552,
        INVOICE_WITH_GLOSS: 2550,
        GENERATED: 1989,
        FULLY_PAID: 1796,  
        PARTIALLY_PAID: 1797,
        PAID_AND_PARTIALLY_PUNISHED: 2521
      },
      STATES_REQUIRING_OBSERVATIONS: [
        2552,
        2550,
        2521
      ],
      isFormValid: true,
      originalInvoiceItems: [],
      PARTIALLY_VOIDED_STATUS_ID: 5,
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'fullInvoice'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`

    this.id = router.currentRoute.params.id
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    if (this.id) {
      await this.fetchElement()
    }
    this.invoiceStatusList = await this.fetchInvoiceStatus()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  computed: {

    observationsValidState() {
      if (this.requiresObservations) {
        return this.elementData?.observations?.trim() ? true : false
      }
      return null
    },
    servicesCalculations() {
      return this.invoiceItems.reduce((acc, item) => {
        // Usar los campos del modelo actual
        const baseValue = parseFloat(item.value) || 0;
        const ivaAmount = parseFloat(item.iva) || 0;
        const totalValue = parseFloat(item.value_iva) || 0;
        
        acc.baseValue += baseValue;
        acc.ivaValue += ivaAmount;
        acc.totalValue += totalValue;
        
        return acc;
      }, { baseValue: 0, ivaValue: 0, totalValue: 0 });
    },

    calculatedValues() {
      const { baseValue, ivaValue, totalValue } = this.servicesCalculations
      
      // Valores de impuestos y deducciones
      const fuente = parseFloat(this.elementData.fuente) || 0
      const ica = parseFloat(this.elementData.ica) || 0
      const otherTaxes = parseFloat(this.elementData.other_taxes) || 0
      const receivedValue = parseFloat(this.elementData.received_value) || 0

      // Cálculo de deducciones totales
      const deductions = fuente + ica + otherTaxes

      // Valor neto después de deducciones
      const netValue = totalValue - deductions

      // Balance después de pagos recibidos
      const balance = netValue - receivedValue

      return {
        baseValue: Math.round(baseValue),
        ivaValue: Math.round(ivaValue),
        chargedValue: Math.round(totalValue),
        netValue: Math.round(netValue),
        balance: Math.round(balance)
      }
    },
    isPartiallyVoided() {
      return this.elementData?.billing_status_id === this.INVOICE_STATES.PARTIALLY_VOIDED
    },

    isFullyVoided() {
      return this.elementData?.billing_status_id === this.INVOICE_STATES.FULLY_VOIDED
    },

    requiresObservations() {
      console.log('Checking requiresObservations:', {
        currentStatusId: this.elementData?.billing_status_id,
        requiringStates: this.STATES_REQUIRING_OBSERVATIONS,
        isRequired: this.STATES_REQUIRING_OBSERVATIONS.includes(this.elementData?.billing_status_id)
      })
      return this.STATES_REQUIRING_OBSERVATIONS.includes(this.elementData?.billing_status_id)
    },

    canSubmitForm() {
      console.log('Checking canSubmitForm:', {
        requiresObservations: this.requiresObservations,
        hasObservations: Boolean(this.elementData?.observations),
        observationsValue: this.elementData?.observations,
        trimmedObservations: this.elementData?.observations?.trim()
      })
      if (this.requiresObservations) {
        if (!this.elementData?.observations || !this.elementData.observations.trim()) {
          return false
        }
      }
      if (this.isPartiallyVoided) {
        const totalServices = this.invoiceItems.length
        const selectedCount = this.selectedServices.length
        
        return selectedCount > 0 && 
               selectedCount < totalServices && 
               totalServices > 1
      }

      return true
    },
    
    serviceOptions() {
      return this.invoiceItems.map(item => ({
        text: this.getFormattedData(item),
        value: item.id,
        disabled: false
      }))
    },
  },


  methods: {
    handleServiceSelection() {
      this.validatePartialVoid()
    },

    isServiceSelected(serviceId) {
      return this.selectedServices.includes(serviceId)
    },

    getFormattedDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },

    getFormattedData(invoice) {
      return `${invoice.cups} - ${invoice.service_data.description} - Fecha: ${
        moment(invoice.service_date).format('DD/MM/YYYY')} - Cod. Servicio: ${invoice.authorization}`
    },

    getStatusName(statusId) {
      const status = this.invoiceStatusList.find(s => s.id === statusId)
      return status ? status.value : 'actual'
    },

    validateStateChange() {
      const currentStatus = this.elementData?.billing_status_id
      console.log('Validating state change:', {
        currentStatus,
        statusName: this.getStatusName(currentStatus),
        requiresObservations: this.requiresObservations,
        observations: this.elementData?.observations
      })

      if (this.requiresObservations) {
        const observations = this.elementData?.observations?.trim()
        if (!observations) {
          const statusName = this.getStatusName(currentStatus)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Las observaciones son obligatorias para el estado "${statusName}"`
            },
          })
          return false
        }
      }

      // Validaciones específicas para anulación parcial
      if (currentStatus === this.INVOICE_STATES.PARTIALLY_VOIDED) {
        return this.validatePartialVoid()
      }

      return true
    },

    router() {
      return router
    },
    async fetchInvoiceStatus() {
      return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchInvoiceStatus`, { }).then(response => {
        const list = response.data.items
        console.log('Estados de factura disponibles:', list.map(item => ({
          id: item.id,
          value: item.value
        })))

        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    async fetchInvoiceItems(invoiceId) {
      try {
        const response = await store.dispatch(
          `${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchInvoiceItems`, 
          { id: invoiceId }
        )
        this.invoiceItems = response.data.items
        this.originalInvoiceItems = [...response.data.items]
        console.log("Datos de los servicios: ", this.originalInvoiceItems)
        
        if (this.isPartiallyVoided) {
          this.selectedServices = this.invoiceItems
            .filter(item => !item.cancelled)
            .map(item => item.id)
        }
      } catch (error) {
        console.error('Error fetching invoice items:', error)
        this.invoiceItems = []
      }
    },
    fetchElement() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElement`, { id: this.id })
        .then(response => {
          this.elementData = response.data
          this.hasError = false
          console.log('Datos completos de la factura:', this.elementData)
          if (this.elementData.arl_filed_date) {
            this.elementData.arl_filed_date = moment(this.elementData.arl_filed_date)
          }
          if (this.elementData.pay_date) {
            this.elementData.pay_date = moment(this.elementData.pay_date)
          }
          this.invoiceDate = this.getFormattedDate(this.elementData.date)
          this.fetchPatient(this.elementData.patient_id)
          this.fetchInvoiceItems(this.elementData.id)
        })
        .catch(error => {
          console.error('Error fetching element:', error)
          this.hasError = true 
          this.elementData = undefined
        })
    },
    fetchPatient(patient_id) {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_patient`, { id: patient_id })
        .then(response => {
          this.patient = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.patient = undefined
          }
        })
    },
    async save() {
      try {
        console.log('Save initiated:', {
          currentStatus: this.elementData?.billing_status_id,
          requiresObservations: this.requiresObservations,
          hasObservations: Boolean(this.elementData?.observations?.trim()),
          canSubmit: this.canSubmitForm
        })
        if (!this.validateStateChange()) {
          return
        }

        if (!this.canSubmitForm) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: this.requiresObservations 
                ? 'Las observaciones son obligatorias para este estado'
                : 'Por favor, revise las validaciones antes de guardar'
            },
          })
          return
        }

        let servicesStatus
        
        if (this.isFullyVoided) {
          // Si es anulación completa, marcamos todos los servicios como cancelados
          servicesStatus = this.invoiceItems.map(item => ({
            id: item.id,
            cancelled: true
          }))
        } else if (this.isPartiallyVoided) {
          // Para anulación parcial, mantenemos la lógica existente
          servicesStatus = this.invoiceItems.map(item => ({
            id: item.id,
            cancelled: !this.selectedServices.includes(item.id)
          }))
        } else {
          // Para otros estados, mantenemos el estado actual de cancelled
          servicesStatus = this.invoiceItems.map(item => ({
            id: item.id,
            cancelled: item.cancelled
          }))
        }

        const saveData = {
          ...this.elementData,
          iva: Math.round(parseFloat(this.elementData.iva) || 0),
          fuente: Math.round(parseFloat(this.elementData.fuente) || 0),
          ica: Math.round(parseFloat(this.elementData.ica) || 0),
          other_taxes: Math.round(parseFloat(this.elementData.other_taxes) || 0),
          received_value: Math.round(parseFloat(this.elementData.received_value) || 0),

          charged_value: this.calculatedValues.chargedValue,
          net_value: this.calculatedValues.netValue,
          balance: this.calculatedValues.balance,
          services_status: servicesStatus,
          is_partially_voided: this.isPartiallyVoided,
          is_fully_voided: this.isFullyVoided
        }

        const response = await store.dispatch(
          `${this.ELEMENT_APP_STORE_MODULE_NAME}/saveInvoice`, 
          saveData
        )

        if (response.status === 200) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t(`${this.RESOURCES_ELEMENT_NAME}.action_results.update.title`),
              icon: 'CheckIcon',
              variant: 'success',
              text: this.$t(`${this.RESOURCES_ELEMENT_NAME}.action_results.update.success`),
            },
          })
          if (response.data?.credit_note_id) {
            try {
              console.log('Intentando descargar PDF para nota crédito:', response.data.credit_note_id);
              const pdfResponse = await store.dispatch(
                `${this.ELEMENT_APP_STORE_MODULE_NAME}/downloadCreditNotePdf`,
                response.data.credit_note_id
              )
              console.log('Respuesta PDF recibida:', pdfResponse);
              
              // Crear blob y descargar
              const blob = new Blob([pdfResponse.data], { type: 'application/pdf' })
              const url = window.URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url
              link.download = `${response.data.number_credit_note}.pdf`
              link.click()
              window.URL.revokeObjectURL(url)
            } catch (downloadError) {
              console.error('Error downloading PDF:', downloadError)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Error al descargar el PDF de la nota crédito'
                },
              })
            }
          }
          await this.fetchElement()
        }
      } catch (error) {
        console.error('Error saving invoice:', error)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: this.$t(`${this.RESOURCES_ELEMENT_NAME}.action_results.update.failure`),
          },
        })
      }
    },
    validatePartialVoid() {
      if (this.isPartiallyVoided) {
        const totalServices = this.invoiceItems.length
        
        if (totalServices === 1) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Se debe marcar la factura como "Anulada completa", la factura por tener un solo servicio no puede quedar "Anulada parcial".'
            },
          })
          this.elementData.billing_status_id = this.INVOICE_STATES.FULLY_VOIDED
          return false
        }

        const selectedCount = this.selectedServices.length

        if (selectedCount === 0 || selectedCount === totalServices) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: selectedCount === 0 
                ? 'Debe seleccionar al menos un servicio. Si desea anular todos los servicios, use "Anulada completa".'
                : 'No puede seleccionar todos los servicios. Si desea mantener todos los servicios, use otro estado.'
            },
          })
          return false
        }

        return true
      }

      return true
    },
  },
  watch: {
    calculatedValues: {
      handler(newVal) {
        this.elementData.value = newVal.baseValue
        this.elementData.iva = newVal.ivaValue
        this.elementData.charged_value = newVal.chargedValue
        this.elementData.net_value = newVal.netValue
        this.elementData.balance = newVal.balance
      },
      immediate: true,
      deep: true
    },

    'elementData.billing_status_id': {
      handler(newStatus, oldStatus) {
        console.log('Status changed:', {
          oldStatus,
          newStatus,
          isPartiallyVoided: newStatus === this.INVOICE_STATES.PARTIALLY_VOIDED,
          requiresObservations: this.STATES_REQUIRING_OBSERVATIONS.includes(newStatus)
        })
        if (newStatus === this.INVOICE_STATES.PARTIALLY_VOIDED) {
          this.selectedServices = []
          this.validatePartialVoid()
        } else if (this.STATES_REQUIRING_OBSERVATIONS.includes(newStatus)) {
          // No validar en el montaje inicial
          if (oldStatus) {
            const statusName = this.getStatusName(newStatus)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Aviso',
                icon: 'InfoIcon',
                variant: 'warning',
                text: `El estado "${statusName}" requiere el diligenciamiento obligatorio de las observaciones`
              },
            })
          }
          this.validateStateChange()
        }
      },
      immediate: true
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.service-item {
  padding: 0.5rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  
  &.service-inactive {
    background-color: #f8f9fa;
    color: #6c757d;
    .custom-control-label {
      opacity: 0.7;
    }
  }

  &.service-cancelled {
    color: #dc3545; 
    text-decoration: line-through;
    .custom-control-label {
      &::before {
        border-color: #dc3545;
      }
    }
  }
}
.btn.service-cancelled {
  color: #dc3545 !important;
  text-decoration: line-through;
  border-color: #dc3545;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
